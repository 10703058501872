import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import $ from "jquery";
import Select from "react-select";
import { navigate } from "@reach/router"
import SearchInputBox from '../predictive-search/search-inputbox';
import SiteSearch from './sitesearch.js'
import "./assets/styles/_index.scss"

const SearchOverlay = (props) => {

    function closeSearchOverlay(e) {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }

    useEffect(() => {
        let url = "/";

        $(".sales_overlay_btn").click(function() {
            var searcValue = $(".property_search_overlay .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();

            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            //url += "include-sold/"
            var selectedDropdownSearch = $('#search_dropdown').val();
            navigate(selectedDropdownSearch+'for-sale/'+url);
            setTimeout(function(){
                $("body.modal-open").removeClass("modal-open")
                $(".search-overlay").removeClass("active-search")
                $(".search-modal").remove();
            }, 1000);
        })

        $(".rent_overlay_btn").click(function() {
            var searcValue = $(".property_search_overlay .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();

            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            //url += "include-let/"
            var selectedDropdownSearch = $('#search_dropdown').val();
            navigate(selectedDropdownSearch+'to-rent/'+url);
            setTimeout(function(){
                $("body.modal-open").removeClass("modal-open")
                $(".search-overlay").removeClass("active-search")
                $(".search-modal").remove();
            }, 1000);
        })
    },[]);

    return(
        <>
            <div className="search-overlay">
                <Link to="#" onClick={closeSearchOverlay}><i className="icon icon-search-close"></i></Link>
                <Container className="property-search-overlay_vertical_align">
                    <Row>
                        <Col lg={10}>
                            <Tabs defaultActiveKey="sitesearch" id="uncontrolled-tab-example" className="search-tab">
                                <Tab eventKey="sitesearch" title="Site Search">
                                    <SiteSearch indexName={process.env.GATSBY_ALGOLIA_SITE_SEARCH_NAME} />
                                </Tab>
                                <Tab eventKey="propertysearch" title="Property Search">
                                    <div className="input-group mb-3 property-search-overlay">
                                        <select className="search-select" id="search_dropdown">
                                            <option value="/property/">Residential</option>
                                            <option value="/property/commercial/">Commercial</option>
                                            <option value="/property/new-homes/">New Homes</option>
                                        </select>
                                        <div className="input-group-prepend property-search-width property-search_overlay property_search_overlay">
                                            <SearchInputBox />
                                        </div>
                                        <div className="property-search-btn_wrapper">
                                            <a href="javascript:void(0)" className="btn sales_overlay_btn">Buy</a>
                                            <a href="javascript:void(0)" className="btn rent_overlay_btn">Rent</a>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SearchOverlay
