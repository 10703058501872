import React,{useEffect, useState} from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import {Button, Card, Accordion, Container} from 'react-bootstrap';
import Starberry from "../../images/starberry.svg";
import CookieConsent from "react-cookie-consent";
import PopularSearch from "../popular-search/static"
import PopularSearchStaticDetails from "../popular-search/static-details"
import PopularSearchResults from "../popular-search/search-results"
import PopularSearchResultsCommercial from "../popular-search/search-results-commercial"
import PopularSearchDetails from "../popular-search/property-details"


import { GetURL } from "../common/site/functions";

import  "../footer/Footer.scss";


const Footer = (props) => {
    const [toggleActive, settoggleActive] = useState(null)
    const toggleClick = (event) => {
    if(toggleActive === event){
    settoggleActive(null)
    }
    else {
    settoggleActive(event)

    }
    
    }
    const [newClass, setNewClass] = useState(false)


    const addNew = () => {
        setNewClass(!newClass)
    }

    const year = new Date().getFullYear();

  const data = useStaticQuery(graphql`
    query FooterQuery{
      strapiMenuFooter {
        Add_Column {
          Parent_Label
          Parent_URL {
            id
          }
          Add_Sub_Menus {
            Label
            URL {
              id
            }
          }
        }
      }

    strapiSiteConfig {
        Bottom_Navigations {
          Label
          Link {
            id
          }
        }
        Footer_Logos {
          Upload_Image {
            publicURL
          }
        }
      }

    }
  `);


    return(
  <footer className={`footer components-footer-footer${props.footerClass ? ' ' + props.footerClass : ''}`}>
    <Container >


                {props.popularSearch ==='propertyresults' || props.popularSearch ==='newhomespropertyresults' ?
                  <Accordion className="popular-search d-lg-block">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                           <Link > Popular Searches</Link>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="101">
                        <Card.Body>
                        <PopularSearchResults searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                : props.popularSearch === 'commercialpropertyresults' ? 
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                        <PopularSearchResultsCommercial searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion> : props.Select_Popular_Search ?
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                <PopularSearch popularSearch={props.Select_Popular_Search} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion> : ''
                }

                {props.popularSearch === 'staticdetails' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                  <PopularSearchStaticDetails office={props.office} area={props.area} guides={props.guide} page={props.page} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetails' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetailsnewhomes' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} pagetype="newhomes" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }

                {props.popularSearch === 'propertydetailscommercial' &&
                <Accordion className="popular-search d-lg-block">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle className={newClass ?"active text-uppercase" : "text-uppercase"} onClick={e=>addNew()}  as={Button} variant="link" eventKey="101">
                         <Link > Popular Searches</Link>
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="101">
                      <Card.Body>
                    <PopularSearchDetails propertydetails={props.propertydetails} pagetype="commercial" />
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
                }



        <Accordion className="footer-accordion">
        {data.strapiMenuFooter.Add_Column.map((Add_Column, i) => {
            let url = ''
            if(Add_Column.Parent_URL) {
                url = GetURL(Add_Column.Parent_URL.id)
            }
            return <Card className={i === 6 ? "block-management" : ''}>
                <Card.Header className={toggleActive === i+1 ? "minus" : ''}>
                <Accordion.Toggle as={Button} onClick={e=>toggleClick(i+1)} variant="link" eventKey={i+1}>
                    {url ?
                    <Link to={`/${url}`} className="menu-head">{Add_Column.Parent_Label}</Link> :
                    <span className="menu-head-foot-link">{Add_Column.Parent_Label}</span>
                    }
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i+1}>
                <Card.Body>
                <ul className="footer-nav">
                  {Add_Column.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                    let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                    return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                  })}
                </ul>

                {i === 3 ? <>
                {data.strapiMenuFooter.Add_Column.slice(6).map((Add_Column, i) => {
                let url = GetURL(Add_Column.Parent_URL.id)
                return (
                <div className="block-management-desktop">
                <Link to={`/${url}`} className="menu-head">{Add_Column.Parent_Label}</Link>
                <ul className="footer-nav">
                  {Add_Column.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                    let sub_menu_url = GetURL(Add_Sub_Menus.URL.id)
                    return <li><Link to={`/${sub_menu_url}`}><span>{Add_Sub_Menus.Label}</span></Link></li>
                  })}
                </ul>
                </div>)
                })}
                </> : ''}


                </Card.Body>
                </Accordion.Collapse>
            </Card>
        })}


        </Accordion>
        <div className="secondry-footer d-lg-flex justify-content-between">
            <div className="footer-brands d-flex justify-content-left align-items-center order-2">
                {data.strapiSiteConfig.Footer_Logos && data.strapiSiteConfig.Footer_Logos.map((logo, i) => {
                    return (
                        <>
                            {logo.Upload_Image && 
                                <a>
                                <img loading="lazy" src={logo.Upload_Image.publicURL} alt="PartnerLogo"/>
                                </a>
                            }
                        </>
                    )
                })}
            </div>
            <div className="footer-info order-1">
                <ul className="linkslist">
                    {data.strapiSiteConfig.Bottom_Navigations.map((links, i) => {
                    let url = GetURL(links.Link.id)
                        return (
                            <li><Link to={`/${url}`}>{links.Label}</Link> </li>
                        )
                    })}
                </ul>
                <p className="copy-rgt">&copy; Copyright North London Network Limited {year}. All rights reserved.</p>
                <p className="powerby">Site by <a href="https://starberry.tv/" className="powerby" target="_blank"><img loading="lazy" src={Starberry} alt="Starberry" className="stb-logo"/></a></p>
            </div>
        </div> 
    </Container>


            <CookieConsent
                disableStyles={true}
                buttonClasses="btn btn-primary"
                containerClasses="cookie-popup d-lg-flex d-block"
                contentClasses=""
                buttonClasses="btn btn-link gotit"
                buttonText="Accept Cookies"

            >
                We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.
          </CookieConsent>
          
  </footer>
)
}
export default Footer;
