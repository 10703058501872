// @todo this need to be changed with a generic one, not gatsby specific
import { navigate } from "gatsby";
import Link from './link';

const doRedirect = url => {
    if (typeof window !== `undefined`) {
        window.location.href = url
        // navigate(url);
    }
}

// reading params from location search
const useQuery = ( location, key ) => {
    const query = new URLSearchParams( location.search);
    return query.get(key);
}

// reading params from url
const goBack = () => {
    navigate(-1);
}

const getPidFromUrl = (url = '') => {
    if (!url) {
        url = typeof window !== 'undefined' ? window.location.href : ''
    }

    let urlObj = url.split('?')[0].split('-');// avoid query params from url
    return urlObj[urlObj.length-1].replace('/', '').replace("/#","").split("?")[0];
}

const getPropertyLink = (uriStr, hit) => {
    return `/${uriStr}${hit.slug}-${hit.objectID}/`
}

export { doRedirect, useQuery, goBack, getPidFromUrl, getPropertyLink, Link }
